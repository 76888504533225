import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrPromoteModuleNewReferralCodeView'
})
export default class GtrPromoteModuleNewReferralCodeView extends Vue {
  data () {
    return {
      submitting: false,
      referralCodeType: 'SYSTEM',
      referralCodeTypeItems: [
        { text: 'System Generated', value: 'SYSTEM' },
        { text: 'Create My Own', value: 'CUSTOM' }
      ],
      referralCode: {
        name: '',
        description: '',
        code: ''
      }
    }
  }

  get promoteModule () {
    return this.$route?.meta?.module || 'register'
  }

  get promoteRoute () {
    if (this.promoteModule === 'leads') {
      return 'level-two.modules.leads.promote'
    }
    return 'level-two.modules.registration.promote'
  }

  sanitizeCustomReferralCode (value: string) {
    this.$data.referralCode.code = value.replace(/[^0-9a-z-]/gi, '_')
  }

  async submit () {
    const form = (this.$refs.newReferralCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          data: {
            name: this.$data.referralCode.name,
            description: this.$data.referralCode.description,
            type: 'REGISTRATION'
          }
        }
        if (this.$data.referralCodeType === 'CUSTOM') {
          payload.data.code = this.$data.referralCode.code
        }
        if (this.promoteModule === 'leads') {
          payload.data.type = 'LRORDERS'
        }
        await this.$store.dispatch('promote/createReferralCode', payload)
        Container.get(Notification).success('Referral code successfully created.')
        this.$router.push({
          name: this.promoteRoute
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
